
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap');

.main{
    font-family: 'Roboto', sans-serif;
    padding-top: 30px;
    padding-bottom: 80px;
    width: 100%;
}


.home_center {
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100%-20px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alignrow{
    height: max-content;
    width: 100%;
    display: flex;
}

.videocontainer{
    width: 65%;
}

.video{
    width: 100%;
}



.descriptioncontainer{
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 10px;
    width:30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.descriptioncontainer p{
    color: grey;
}

.photocontainer{
    padding-left: 50px;
    padding-right: 50px;
    box-sizing: border-box;
    width: 98%;
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.link{
    align-self:flex-end; 
    margin-bottom: 50px;
}


.link a{
    color: black;
    font-size: 1.2rem;
}




.infocontainer { 
    margin-top: 300px;
    width: 100%;
    display: flex;
    justify-content: center;

}

.info1{
    font-size: 2rem;
    width: 30%;
}

.info2{
    width: 30%;
  
}

.title{
    margin-bottom: 50px;
    font-size: 1.5rem;
}

.content p{
    margin-bottom: 20px;
    color: grey;
}

@media only screen and (max-width: 480px){
    .alignrow{
        height: max-content;
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .videocontainer{
        width: 100%;
    }

    .descriptioncontainer{
        margin-top: 5%;
        width:100%;
    }
    .photocontainer{
        padding-left: 0;
        padding-right: 0;
        margin-top: 15%;
        width: 100%;
    }

    .link{
        align-self:flex-end; 
        margin-bottom: 5%;
    }

    .infocontainer { 
        margin-top: 15%;
        width: 95%;
        margin-left: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
    
    }

    .info1{
        margin-bottom: 5%;
        font-size: 2rem;
        width: 100%;
    }
    
    .info2{
        width: 100%;
    }
    
    .title{
        margin-bottom: 5%;
        font-size: 1.5rem;
    }
    
    .content p{
        margin-bottom: 3%;
    }
  


}
