
.card:hover{
  opacity: 0.7;
}

.card img{
 width: 100%;
 max-height: 670px;
}


