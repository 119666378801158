@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;1,300&display=swap');

.form {
    position: relative;
    font-family: 'Roboto', sans-serif;
    margin-top: 1rem;
    margin-left:5%;
    width: 80%;
    height: 75vh;
    overflow-y: scroll;
  }

  .title {
    border-bottom: 2px solid black;
    margin-bottom: 1rem;
  }
  
  .control {
    width: 100%;
  
    margin-bottom: 0.8rem;
    display:flex;
    flex-direction: column;
  }

  .control select {
    font: inherit;
    height: 10%;
    font-size: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;

  }

  .control label {
    font-size: 0.8rem;
    margin-right: 1.5rem;
    margin-bottom: 0.25rem;
  }
  
  .control input {
    font: inherit;
    height: 10%;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    
  }
 
  .invalid {
    margin-bottom: 0.4rem;

  }
  .invalid p{
    font-size: 0.9rem;
  }

  .invalid label {
    color: #ca3e51;
  }
  
  .invalid input {
    border-color: #aa0b20;
    background-color: #ffeff1;
    margin-bottom: -0.6rem;
  }

  @media only screen and (max-width: 480px){

    .form {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
      padding-bottom: 5%;
      
    }

  }