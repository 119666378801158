.dropdownmenu{
   border: solid 1px rgb(209, 209, 209);
   width: 100%;
   padding-top: 15px;
   padding-bottom: 15px;
   background-color: white;
   display:flex;
   flex-direction: column;
}

.dropdownitem{
    width: 100%;
    height: 35px;
    text-align: center;
}

.dropdownitem:hover{
   background-color: rgb(235, 235, 235);
}

.dropdownitem a{
    color: black;
    text-decoration: none;
    font-size: 1.3rem;
    cursor: pointer;
}

.dropdownitem:hover a{
    color: black;
    border: none;
}
