.slidecontainer { 
    position: relative;
    width: 100%;  
}

.piccontainer{
   white-space: nowrap;
   width: 100%;
   height: 100%;
   overflow-x: scroll;
   scroll-behavior: smooth;
   display: flex;
   align-items: center;
}

.piccontainer img { 
    display: inline-block;
    margin-left: 8px;
    margin-right: 8px;
    width: 320px;
    max-height: 420px;
    object-fit: cover;
}

.buttonleft{
    position: absolute;
    left: 0px;
    top:50%;
    z-index: 5;
}

.buttonright{
    position: absolute;
    right: 0px;
    top:50%;
    z-index: 5;


}

.icon{
    border-radius: 20px;
    background-color: transparent;
    width: 50px;
    height: 50px;
    opacity: 80%;
    color: white;
    font-size: 2rem;
    cursor: pointer;
   
}

@media only screen and (max-width: 480px){
    .icon{
        border-radius: 10px;
        background-color: transparent;
        width: 30px;
        height: 30px;
        opacity: 80%;
        color: white;
        font-size: 2rem;
        cursor: pointer;
       
    }

}
